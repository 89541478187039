define('wnyc-studios-web-client/utils/redirect-series', ['exports', 'wnyc-studios-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = redirectSeries;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  /**
   * Series/Projects Redirects
   *
   * "Series" can exist at two different url patterns:
   *   /shows/<show_slug>/project/<series_slug>
   *   /series/<series_slug>
   *
   * This function compares the paths of the urls from the
   * series api and the current location and redirects to
   * the api url if the paths don't match.
   *
   * It also redirects to the api url if the siteId doesn't
   * match the current siteId, from the config.
   *
  **/

  function redirectSeries(route, series, fastbootPath) {
    var channelURL = Ember.get(series, 'url');

    try {
      if (typeof FastBoot !== 'undefined') {
        var URL = FastBoot.require('url-parse');
        channelURL = new URL(channelURL);
      } else {
        var anchor = document.createElement('a');
        anchor.href = channelURL;
        channelURL = anchor;
      }
    } catch (e) {
      return;
    }

    if (!fastbootPath && Ember.get(series, 'siteId') !== _environment.default.siteId) {
      window.location.replace(channelURL.href);
      return;
    }

    var currentPath = fastbootPath || window.location.pathname;
    var channelPath = channelURL.pathname;

    if (!currentPath.startsWith(channelPath)) {
      var seriesMatcher = /series\/([-a-z0-9_]+)\/?/;
      var projectMatcher = /shows\/([-a-z0-9_]+)\/projects\/([-a-z0-9_]+)\/?/;
      var seriesMatch = seriesMatcher.exec(channelPath);
      var projectMatch = projectMatcher.exec(channelPath);
      if (seriesMatch) {
        var _seriesMatch = _slicedToArray(seriesMatch, 2),
            _series = _seriesMatch[1];

        route.replaceWith('series-detail', _series);
      } else if (projectMatch) {
        var _projectMatch = _slicedToArray(projectMatch, 3),
            show = _projectMatch[1],
            project = _projectMatch[2];

        route.replaceWith('show-detail.project.index', show, project);
      }
    }
  }
});