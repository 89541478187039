define('wnyc-studios-web-client/instance-initializers/ember-hifi', ['exports', 'wnyc-studios-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    var defaultConnections = [{ name: 'NativeAudio', config: {} }, { name: 'HLS', config: {} }, { name: 'Howler', config: {} }];

    var _config$emberHifi = _environment.default.emberHifi,
        emberHifi = _config$emberHifi === undefined ? {
      debug: false
    } : _config$emberHifi;


    if (emberHifi.connections) {
      // Connections were specified in consumer environment file

      if (emberHifi.connections.length === 0) {
        console.warn('[ember-hifi] No hifi connections were specified, using defaults.'); // eslint-disable-line
        emberHifi.connections = defaultConnections;
      }
    } else {
      // none were specified, use defaults
      emberHifi.connections = defaultConnections;
    }

    var _config$environment = _environment.default.environment,
        environment = _config$environment === undefined ? 'development' : _config$environment;

    var options = { emberHifi: emberHifi, environment: environment };
    application.register('config:hifi', options, { instantiate: false });
    application.inject('service:hifi', 'options', 'config:hifi');
  }

  exports.default = {
    name: 'ember-hifi',
    initialize: initialize
  };
});