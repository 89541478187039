define("wnyc-studios-web-client/components/side-nav", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LINKS = [{
    title: "facebook",
    href: "https://www.facebook.com/WNYCStudios/"
  }, {
    title: "twitter",
    href: "https://twitter.com/WNYCStudios"
  }, {
    title: "youtube",
    href: "https://www.youtube.com/channel/UCrTWMuZyAIRP_IRxfO20k3w/videos"
  }];

  exports.default = Ember.Component.extend({
    studiosSocialLinks: LINKS
  });
});