define('wnyc-studios-web-client/utils/wrap-ajax', ['exports', 'nypr-django-for-ember/utils/wrap-ajax'], function (exports, _wrapAjax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _wrapAjax.default;
    }
  });
});