define('wnyc-studios-web-client/models/show', ['exports', 'ember-data', 'wnyc-studios-web-client/models/channel'], function (exports, _emberData, _channel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _channel.default.extend({
    image: _emberData.default.belongsTo('image'),
    producingOrganizations: _emberData.default.hasMany('producing-organization'),
    studiosMarqueeImage: _emberData.default.belongsTo('image')
  });
});