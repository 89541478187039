define('wnyc-studios-web-client/utils/compat-hooks', ['exports', 'nypr-django-for-ember/utils/compat-hooks'], function (exports, _compatHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'beforeTeardown', {
    enumerable: true,
    get: function () {
      return _compatHooks.beforeTeardown;
    }
  });
  Object.defineProperty(exports, 'beforeAppend', {
    enumerable: true,
    get: function () {
      return _compatHooks.beforeAppend;
    }
  });
  Object.defineProperty(exports, 'mangleJavascript', {
    enumerable: true,
    get: function () {
      return _compatHooks.mangleJavascript;
    }
  });
  Object.defineProperty(exports, 'serializeInlineDoc', {
    enumerable: true,
    get: function () {
      return _compatHooks.serializeInlineDoc;
    }
  });
  Object.defineProperty(exports, 'retryFromServer', {
    enumerable: true,
    get: function () {
      return _compatHooks.retryFromServer;
    }
  });
});