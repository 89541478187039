define('wnyc-studios-web-client/services/dj', ['exports', 'nypr-audio-services/services/dj'], function (exports, _dj) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _dj.default;
    }
  });
});