define('wnyc-studios-web-client/components/suggested-shows', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['suggested-shows'],
    title: 'Shows You Might Like'
  });
});