define('wnyc-studios-web-client/components/nypr-story/series', ['exports', 'nypr-ui/components/nypr-story/series'], function (exports, _series) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _series.default;
    }
  });
});