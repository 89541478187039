define('wnyc-studios-web-client/services/wnyc-routing', ['exports', 'nypr-django-for-ember/services/wnyc-routing'], function (exports, _wnycRouting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _wnycRouting.default;
    }
  });
});