define('wnyc-studios-web-client/components/stream-list', ['exports', 'nypr-publisher-lib/components/stream-list'], function (exports, _streamList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _streamList.default;
    }
  });
});