define('wnyc-studios-web-client/routes/shows', ['exports', 'wnyc-studios-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),
    metadata: Ember.inject.service(),
    titleToken: 'All Podcasts',
    pageType: 'All Podcasts',
    scroller: Ember.inject.service('scroller'),
    model: function model() {
      return Ember.RSVP.hash({
        shows: this.store.query('show', { "site_id": _environment.default.siteId }),
        featuredShows: this.store.findRecord("bucket", 'wnyc-studios-featured-shows')
      });
    },
    afterModel: function afterModel() {
      this.get('metadata').setHeadData({
        path: '/podcasts'
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('shows', model.shows);
      controller.set('featuredShows', model.featuredShows);
      controller.set('studiosSocialLinks', [{
        title: "facebook",
        href: "https://www.facebook.com/WNYCStudios/"
      }, {
        title: "twitter",
        href: "https://twitter.com/WNYCStudios"
      }]);
    },

    actions: {
      didTransition: function didTransition() {
        if (window && window.document) {
          this.get('dataLayer').push('Page Type', this.get('pageType'));
        }
        var scrollTo = this.controller.get('scrollTo');
        if (scrollTo) {
          var scroll = Ember.get(this, 'scroller');
          Ember.run.scheduleOnce('afterRender', function () {
            if (document.querySelector(scrollTo)) {
              var options = { offset: -70 };
              scroll.scrollVertical(scrollTo, options);
            }
          });
        }
        return true;
      },
      willTransition: function willTransition() {
        this.get('dataLayer').clear('Page Type');
        return true;
      }
    }
  });
});