define('wnyc-studios-web-client/helpers/one-at-a-time', ['exports', 'ember-hifi/helpers/one-at-a-time'], function (exports, _oneAtATime) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _oneAtATime.default;
    }
  });
  Object.defineProperty(exports, 'oneAtATime', {
    enumerable: true,
    get: function () {
      return _oneAtATime.oneAtATime;
    }
  });
});