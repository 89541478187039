define('wnyc-studios-web-client/routes/show-detail/page', ['exports', 'wnyc-studios-web-client/mixins/listing-route'], function (exports, _listingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Route.extend(_listingRoute.default, {
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),

    titleToken: function titleToken(_ref) {
      var navPage = _ref.navPage,
          flatPage = _ref.flatPage;

      var linkroll = Ember.get(this.modelFor(this.channelType).channel, 'linkroll');
      if (flatPage) {
        return ': ' + Ember.get(flatPage, 'title');
      } else if (navPage && linkroll) {
        var link = linkroll.find(function (link) {
          return navPage.id.match(link['nav-slug']);
        });
        return ': ' + link.title;
      }
    },
    model: function model(_ref2, transition) {
      var _this = this;

      var page_params = _ref2.page_params;
      var slug = transition.params[this.channelType].slug;

      var _page_params$split = page_params.split('/'),
          _page_params$split2 = _slicedToArray(_page_params$split, 2),
          navSlug = _page_params$split2[0],
          page = _page_params$split2[1];

      if (!page && /^\d+$/.test(navSlug)) {
        // navSlug is a page, so URL is something like podcasts/bl/5
        // we use recent_stories as the lookup for those stories
        page = navSlug;
        navSlug = 'recent_stories';
      }

      var navPageID = this.channelPathName + '/' + slug + '/' + navSlug + '/' + (page || 1);
      var flatpageURL = '/' + this.channelPathName + '/' + slug + '/' + navSlug + '/';
      Ember.set(this, 'pageNumbers.totalPages', 0);

      var controller = this.controllerFor(this.channelType);
      controller.send('setNavSlug', navSlug);

      var pageSize = 9;
      if (navSlug === 'the-team') {
        pageSize = 50;
      }

      return Ember.RSVP.hash({
        navPage: this.store.findRecord('api-response', navPageID, { adapterOptions: { limit: pageSize } }).then(function (apiResponse) {
          // wait until models are loaded to keep UI consistent
          Ember.set(_this, 'pageNumbers.page', Number(page) || 1);
          Ember.set(_this, 'pageNumbers.totalPages', Number(Ember.get(apiResponse, 'totalPages')));

          return apiResponse;
        }).catch(function (err) {
          if (!err.isAdapterError) {
            throw err;
          }
        }),
        flatPage: this.store.query('flat-page', {
          filter: {
            url: flatpageURL
          }
        }).then(function (flatPages) {
          return flatPages.get("firstObject");
        }).catch(function (err) {
          if (!err.isAdapterError) {
            throw err;
          }
        })
      }).then(function (pages) {
        if (!pages.flatPage && !pages.navPage) {
          throw {
            status: 404,
            response: 'No flat page or linkroll found at \'' + flatpageURL + '\''
          };
        }
        return pages;
      });
    },
    afterModel: function afterModel() {
      var controller = this.controllerFor(this.channelType);
      controller.send('updateNav');
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('navPage', Ember.get(model, 'navPage'));
      controller.set('flatPage', Ember.get(model, 'flatPage'));
    },

    actions: {
      didTransition: function didTransition() {
        var model = this.currentModel;
        if (window && window.document) {
          if (Ember.get(model, 'flatPage.superchunkHeadPlusContent')) {
            Ember.get(this, 'dataLayer').push('Page Type', 'Show Flatpage');
          } else {
            var contentType = Ember.get(model, 'navPage.contentType');
            Ember.get(this, 'dataLayer').push('Content Type', contentType);
            // Set story details for nav linkroll stories.
            if (contentType === 'story-detail') {
              var story = Ember.get(model, 'navPage.story');
              Ember.get(this, 'dataLayer').setForType('story', story);
            }
          }
        }
        return true;
      },
      willTransition: function willTransition() {
        Ember.get(this, 'dataLayer').clear('Page Type');
        Ember.get(this, 'dataLayer').clear('Content Type');
        Ember.get(this, 'dataLayer').clearForType('story');
        return true;
      }
    }
  });
});