define('wnyc-studios-web-client/routes/show-detail/project/page', ['exports', 'wnyc-studios-web-client/mixins/listing-route', 'wnyc-studios-web-client/utils/redirect-series'], function (exports, _listingRoute, _redirectSeries) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_listingRoute.default, {
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),
    fastboot: Ember.inject.service(),
    pageType: 'Series',
    projectsPath: null,

    titleToken: function titleToken(_ref) {
      var project = _ref.project;

      return Ember.get(project, 'title');
    },
    model: function model(params) {
      var _this = this;

      var channelPathName = 'series';
      var slug = this.paramsFor('show-detail.project')["project_slug"];
      var page = params["page"];

      var projectId = channelPathName + '/' + slug + '/' + (page || 1);
      var storiesId = channelPathName + '/' + slug + '/recent_stories/' + (page || 1);
      Ember.set(this, 'pageNumbers.totalPages', 0);

      var pageSize = 9;

      return Ember.RSVP.hash({
        project: this.store.findRecord('channel', projectId, { adapterOptions: { limit: pageSize } }),
        apiResponse: this.store.findRecord('api-response', storiesId, { adapterOptions: { limit: pageSize } }).then(function (m) {
          // wait until models are loaded to keep UI consistent
          Ember.set(_this, 'pageNumbers.page', Number(page) || 1);
          Ember.set(_this, 'pageNumbers.totalPages', Number(Ember.get(m, 'totalPages')));
          return m;
        })
      }).then(function (models) {
        if (!models.apiResponse) {
          throw {
            status: 404,
            response: 'no project found at ' + projectId
          };
        }
        return models;
      });
    },
    afterModel: function afterModel(_ref2) {
      var project = _ref2.project;

      (0, _redirectSeries.default)(this, project, Ember.get(this, 'fastboot.request.path'));
    },
    setupController: function setupController(controller, _ref3) {
      var project = _ref3.project,
          apiResponse = _ref3.apiResponse;

      this._super.apply(this, arguments);
      controller.set('project', project);
      controller.set('apiResponse', apiResponse);
      var projectsPath = '/podcasts/' + this.paramsFor('show-detail')["slug"] + '/projects';
      controller.set('projectsPath', projectsPath);
    },

    actions: {
      didTransition: function didTransition() {
        if (window && window.document) {
          this.get('dataLayer').push('Page Type', this.get('pageType'));
        }
        return true;
      },
      willTransition: function willTransition() {
        if (window && window.document) {
          this.get('dataLayer').clear('Page Type');
        }
        return true;
      }
    }
  });
});