define('wnyc-studios-web-client/components/stream-banner/station-name', ['exports', 'nypr-audio-services/components/stream-banner/station-name'], function (exports, _stationName) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _stationName.default;
    }
  });
});