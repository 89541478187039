define('wnyc-studios-web-client/mixins/global-show-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    afterModel: function afterModel(model) {
      if (model && model.show) {
        this.controllerFor('application').set('show', model.show);
      } else if (model && model.channel) {
        this.controllerFor('application').set('show', model.channel);
      }
    },
    willTransition: function willTransition() {
      this.controllerFor('application').set('show', null);
    }
  });
});