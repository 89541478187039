define('wnyc-studios-web-client/controllers/shows', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['scrollTo'],
    scrollTo: null
  });
});