define('wnyc-studios-web-client/controllers/show-detail', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    showCalloutChunk: Ember.computed('model.showCalloutChunk', {
      get: function get() {
        var chunk = Ember.get(this, 'model.showCalloutChunk');
        if (chunk) {
          var text = chunk.get('content').replace(/\\x3C\/script>/g, '</script>');
          return this.store.createRecord('django-page', { text: text });
        } else {
          return '';
        }
      }
    }),
    actions: {
      setNavSlug: function setNavSlug(navSlug) {
        Ember.set(this, 'navSlug', navSlug);
      },
      updateNav: function updateNav() {
        var navSlug = this.get('navSlug');
        var linkroll = this.get('show.linkroll');
        var activeTabIndex = 0;
        if (linkroll) {
          var index = linkroll.findIndex(function (link) {
            return link['nav-slug'] === navSlug;
          });
          if (index > 0) {
            activeTabIndex = index;
          }
        }
        Ember.set(this, 'activeTabIndex', activeTabIndex);
        Ember.set(this, 'isHomepageForShow', activeTabIndex === 0);
      }
    }
  });
});