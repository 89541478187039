define('wnyc-studios-web-client/transitions', ['exports', 'wnyc-studios-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    this.setDefault({ duration: duration(250) });
    this.transition(this.includingInitialRender(), this.childOf('.nav-links'), this.use('navigationBar'));
    this.transition(this.hasClass('nypr-player-wrapper'), this.use('playerReveal'));
  };

  function duration(time) {
    var skipAnimation = false;
    if (_environment.default.environment === 'test' || window.localStorage.skipAnimation) {
      skipAnimation = true;
    }
    return skipAnimation ? 0 : time;
  }
});