define('wnyc-studios-web-client/components/subscribe-modal-overlay', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["subscribe-modal-overlay"],
    actions: {
      closeModal: function closeModal() {
        this.closeModal();
      }
    },

    closeModal: function closeModal() {
      this.get('onClose')();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._initEscListener();
      //focus on modal close button
      this.$('button.subscribe-overlay__close-button').focus();
      //hide rest of page from aria
      Ember.$("body>.ember-view").attr("aria-hidden", true);
    },
    didDestroyElement: function didDestroyElement() {
      this._destroyEscListener();
      //aria show rest of page
      Ember.$("body>.ember-view").attr("aria-hidden", false);
    },
    _initEscListener: function _initEscListener() {
      var _this = this;

      var closeOnEscapeKey = function closeOnEscapeKey(ev) {
        if (ev.key === "Escape") {
          _this.closeModal();
        }
      };

      Ember.$('body').on('keyup.subscribe-modal-overlay', closeOnEscapeKey);
    },
    _destroyEscListener: function _destroyEscListener() {
      Ember.$('body').off('keyup.subscribe-modal-overlay');
    }
  });
});