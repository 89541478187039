define('wnyc-studios-web-client/router', ['exports', 'wnyc-studios-web-client/config/environment', 'ember-router-scroll'], function (exports, _environment, _emberRouterScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_emberRouterScroll.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    webRoot: _environment.default.webRoot,
    headData: Ember.inject.service(),

    setTitle: function setTitle(title) {
      this.get('headData').set('title', title);
    }
  });

  var subpageRoutes = function subpageRoutes() {
    this.route('page', { path: '*page_params' });
  };

  Router.map(function () {
    this.route('story', { path: 'story/:slug/:extra' });
    this.route('story', { path: 'story/:slug' });
    this.route('story', { path: 'podcasts/:podcast_slug/:item_type/:slug' });
    this.route('people');
    this.route('person-detail', { path: 'people/:slug' });
    this.route('shows');
    this.route('shows', { path: 'podcasts' });
    this.route('archive', { path: 'shows/:uslug/archive' });
    this.route('archive', { path: 'podcasts/:uslug/archive' });
    this.route('show-detail', { path: 'shows/:slug' }, function () {
      this.route('project', { path: 'projects/:project_slug' }, function () {
        this.route('page', { path: ':page' });
      });
      this.route('page', { path: '*page_params' });
    });
    this.route('show-detail', { path: 'podcasts/:slug' }, function () {
      this.route('project', { path: 'projects/:project_slug' }, function () {
        this.route('page', { path: ':page' });
      });
      this.route('page', { path: '*page_params' });
    });
    this.route('series-detail', { path: 'series/:slug' }, subpageRoutes);
    if (_environment.default.environment !== 'production') {
      this.route('freestyle', { path: 'styleguide' }, subpageRoutes);
    }
    this.route('missing', { path: '*' });
    this.route('flat-page', { path: '*flatpage_path' });
  });

  exports.default = Router;
});