define('wnyc-studios-web-client/helpers/can-change-volume', ['exports', 'nypr-player/helpers/can-change-volume'], function (exports, _canChangeVolume) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _canChangeVolume.default;
    }
  });
  Object.defineProperty(exports, 'canChangeVolume', {
    enumerable: true,
    get: function () {
      return _canChangeVolume.canChangeVolume;
    }
  });
});