define("wnyc-studios-web-client/routes/series-detail", ["exports", "wnyc-studios-web-client/utils/redirect-series"], function (exports, _redirectSeries) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    dataPipeline: Ember.inject.service(),
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),
    fastboot: Ember.inject.service(),
    metadata: Ember.inject.service(),
    titleToken: function titleToken(model) {
      return model.channel.get('title');
    },
    pageType: 'Series',
    model: function model(_ref) {
      var slug = _ref.slug;

      return Ember.RSVP.hash({
        channel: this.store.findRecord("channel", "series/" + slug, { adapterOptions: { limit: 9 } }),
        showsYouMightLike: this.store.findRecord("bucket", slug + "-shows-you-might-like")
      });
    },
    afterModel: function afterModel(_ref2, transition) {
      var channel = _ref2.channel;


      (0, _redirectSeries.default)(this, channel, Ember.get(this, 'fastboot.request.path'));

      this.get('metadata').setHeadData({
        path: Ember.get(transition, 'intent.url'),
        twitterCard: 'summary_large_image',
        description: Ember.get(channel, 'tease'),
        image: Ember.get(channel, 'fbImage') ? Ember.get(channel, 'fbImage') : Ember.get(channel, 'logoImage')
      });
    },
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);
      var series = model.channel;
      if (window && window.document) {
        controller.set("series", series);
        controller.set("showsYouMightLike", model.showsYouMightLike);
      }
      this.store.findRecord('chunk', series.get('slug') + "-series-callout").then(function (chunk) {
        controller.set('model.seriesCalloutChunk', chunk);
      }).catch(function (err) {
        if (!err.isAdapterError) {
          throw err;
        }
      });
      var navSlug = transition.params[transition.targetName].page_params;
      if (series.get("linkroll") && !navSlug) {
        navSlug = series.get("linkroll.0.nav-slug");
      }
      controller.set('navSlug', navSlug);
      controller.send('updateNav');
    },

    actions: {
      didTransition: function didTransition() {
        var _this = this;

        var _currentModel = this.currentModel,
            channel = _currentModel.channel,
            showsYouMightLike = _currentModel.showsYouMightLike;


        if (window && window.document) {
          Ember.get(this, 'dataLayer').setForType('series', channel);
          Ember.get(this, 'dataLayer').push('Page Type', Ember.get(this, 'pageType'));
          if (Ember.get(showsYouMightLike, 'bucketItems')) {
            Ember.get(this, 'dataLayer').push('Shows You Might Like', 'Yes');
          } else {
            Ember.get(this, 'dataLayer').push('Shows You Might Like', 'No');
          }
        }

        Ember.run.schedule('afterRender', function () {
          Ember.get(_this, 'dataPipeline').reportItemView({
            cms_id: Ember.get(channel, 'cmsPK'),
            item_type: 'series'
          });
        });
        return true;
      },
      willTransition: function willTransition() {
        if (window && window.document) {
          Ember.get(this, 'dataLayer').clearForType('series');
          Ember.get(this, 'dataLayer').clear('Page Type');
          Ember.get(this, 'dataLayer').clear('Shows You Might Like');
        }
        return true;
      }
    }
  });
});