define('wnyc-studios-web-client/components/horizontal-nav', ['exports', 'wnyc-studios-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var easeOut = function easeOut(time, start, change, duration) {
    return start + change * Math.sin(Math.min(1, time / duration) * (Math.PI / 2));
  };

  exports.default = Ember.Component.extend({
    tagName: 'nav',
    classNames: ['horizontal-nav'],
    classNameBindings: ['canScrollLeft:is-left-scrollable', 'canScrollRight:is-right-scrollable'],
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads('fastboot.isFastBoot'),
    didChangeIndex: function didChangeIndex() {},
    parsedLinks: Ember.computed('links', function () {
      var links = Ember.A(Ember.get(this, 'links'));
      return links.map(function (i) {
        if (typeof i.href === 'string' && i.href.indexOf(_environment.default.webRoot) === 0) {
          // make sure the parsed path has a leading slash
          i.href = i.href.replace(_environment.default.webRoot, '').replace(/^([^/]+)/, '/$1');
          return i;
        } else {
          return i;
        }
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);
      // fallback to null if defaultSlug is undefined because a linkrollLink without
      // a navSlug key will match on `undefined` in `findBy` below
      var defaultSlug = Ember.get(this, 'defaultSlug') || null;
      var links = Ember.A(Ember.get(this, 'links'));

      this.links = links;

      var defaultIndex = links.indexOf(links.findBy('nav-slug', defaultSlug));
      Ember.set(this, 'activeTabIndex', defaultIndex === -1 ? 0 : defaultIndex);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      // set the indicator to the correct position
      Ember.run.scheduleOnce('afterRender', this, '_handleDOMChange');

      // save scrolling container for convenience
      var container = this.get('element').querySelector('.horizontal-nav__container');
      this.set('container', container);

      // scroll active tab into view on pageload
      this._scrollToTab(this.get('activeTabIndex'));

      // setup event handlers
      var DOMChangeHandler = function DOMChangeHandler() {
        return Ember.run.debounce(_this, '_handleDOMChange', 100);
      };
      this.set('DOMChangeHandler', DOMChangeHandler);
      container.addEventListener('scroll', DOMChangeHandler);
      window.addEventListener('resize', DOMChangeHandler);
      if (document.fonts && document.fonts.ready) {
        document.fonts.ready.then(DOMChangeHandler);
      }
    },
    willDestroyElement: function willDestroyElement() {
      // teardown event handlers
      var DOMChangeHandler = this.get('DOMChangeHandler');
      this.get('container').removeEventListener('scroll', DOMChangeHandler);
      window.removeEventListener('resize', DOMChangeHandler);
    },
    updateTabIndicator: function updateTabIndicator(tabIndex) {
      if (!this.get('isFastBoot')) {
        var element = this.get('element');
        if (element) {
          var tab = element.querySelector('li:nth-child(' + (tabIndex + 1) + ')');
          if (tab) {
            this.set('indicatorWidth', tab.clientWidth);
            this.set('indicatorX', tab.offsetLeft);
          }
        }
      }
    },


    _handleTabChange: Ember.observer('activeTabIndex', function () {
      var tabIndex = this.get('activeTabIndex');
      if (tabIndex !== undefined && !Number.isNaN(tabIndex)) {
        this.updateTabIndicator(tabIndex);
      }
    }),

    _handleDOMChange: function _handleDOMChange() {
      var container = this.get('container');
      var containerWidth = container.clientWidth;
      var contentWidth = container.scrollWidth;
      var scrollOffset = container.scrollLeft;
      //
      //          contentWidth
      //               |
      //    |-------------------|
      //     __ ___.==========._
      //    |_link_|_link__lin|_|
      //           '=========='
      //    |<---->|<-------->|
      //       /          \
      // scrollOffset  containerWidth (visible area)
      //
      // calculate if scrolling is possible
      // used to controll arrow button visibility
      var canScroll = contentWidth > containerWidth;
      this.set('canScrollLeft', canScroll && scrollOffset > 0);
      this.set('canScrollRight', canScroll && scrollOffset + containerWidth < contentWidth);
      this.set('scrollOffset', scrollOffset);
      this.set('containerWidth', containerWidth);

      // realign the active tab indicator
      // when font-size changes at breakpoints, etc.
      this._handleTabChange();
    },
    _animateScroll: function _animateScroll(element, newPos, duration) {
      var startTime = Date.now();
      var startPos = element.scrollLeft;

      // clamp scrollPosition
      newPos = Math.max(0, newPos);
      newPos = Math.min(element.scrollWidth - element.clientWidth, newPos);

      // calculate distance to scroll
      var change = newPos - startPos;

      // This inner function is called until the elements scrollLeft reaches newPos.
      var updateScroll = function updateScroll() {
        var now = Date.now();
        var time = now - startTime;
        element.scrollLeft = easeOut(time, startPos, change, duration);
        if (element.scrollLeft !== newPos) {
          requestAnimationFrame(updateScroll);
        }
      };
      requestAnimationFrame(updateScroll);
    },
    _scrollTo: function _scrollTo(targetOffset) {
      // scroll directly to an offset
      var container = this.get('container');
      this._animateScroll(container, targetOffset, 250);
    },
    _scrollToTab: function _scrollToTab(i) {
      // scroll a specific tab into view if needed
      var container = this.get('container');
      var containerWidth = container.clientWidth;
      var element = this.get('element');
      var tab = element.querySelector('li:nth-child(' + (i + 1) + ')');
      if (tab) {
        var tabLeft = tab.offsetLeft;
        var tabWidth = tab.clientWidth;
        var tabRight = tab.offsetLeft + tabWidth;
        var scrollOffset = container.scrollLeft;
        var contentWidth = container.scrollWidth;
        if (tabRight + scrollOffset > containerWidth) {
          this._scrollTo(contentWidth - tabWidth);
        } else if (tabLeft < scrollOffset) {
          this._scrollTo(tabLeft);
        }
      }
    },


    actions: {
      scrollBy: function scrollBy(delta) {
        // increment scroll offset by a specific delta value
        var offset = this.get('scrollOffset') + delta;
        this._scrollTo(offset);
      }
    }
  });
});