define('wnyc-studios-web-client/controllers/story', ['exports', 'wnyc-studios-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['tab', 'token', 'content_type_id', 'object_id', '_'],
    story: Ember.computed.alias('model.story'),
    show: Ember.computed.alias('model.show'),
    channel: Ember.computed.alias('model.channel'),
    showsYouMightLike: Ember.computed.alias('model.showsYouMightLike'),
    session: Ember.inject.service(),
    isStaff: Ember.computed.reads('session.data.isStaff'),
    adminURL: _environment.default.adminRoot + '/admin',
    deviceIsIos: null,
    segmentQueue: Ember.inject.service('segment-queue'),
    tab: 'summary',
    setTab: function setTab() {
      if (typeof location !== 'undefined' && location.hash.substr(1) === "transcript") {
        this.set("tab", 'transcript');
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce("afterRender", this, this.setTab);
    },
    didInsertElement: function didInsertElement() {
      var isIOS = typeof navigator !== 'undefined' && !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
      this.set('deviceIsIos', isIOS);
    },

    actions: {
      handlePlayButtonClicked: function handlePlayButtonClicked() {
        if (this.get('story') && this.get('story').get('segments')) {
          this.get('segmentQueue').updateQueue(this.get('story').get('segments').copy());
        }
      }
    }
  });
});