define('wnyc-studios-web-client/components/bucket-story-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['bucket-story-list'],
    bucketItemsSliced: Ember.computed('bucket', 'limit', function () {
      var limit = this.get('limit') || 3;
      return this.get('bucket.bucketItems').slice(0, limit);
    })
  });
});