define('wnyc-studios-web-client/helpers/decamelize-keys', ['exports', 'nypr-auth/helpers/decamelize-keys'], function (exports, _decamelizeKeys) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _decamelizeKeys.default;
    }
  });
  Object.defineProperty(exports, 'decamelizeKeys', {
    enumerable: true,
    get: function () {
      return _decamelizeKeys.decamelizeKeys;
    }
  });
});