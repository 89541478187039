define('wnyc-studios-web-client/adapters/application', ['exports', 'ember-data', 'wnyc-studios-web-client/config/environment', 'ember-fetch/mixins/adapter-fetch'], function (exports, _emberData, _environment, _adapterFetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_adapterFetch.default, {
    authorizer: 'authorizer:nypr',
    host: _environment.default.publisherAPI,
    namespace: 'v3',
    // ember 2.0 deprecation
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    }
  });
});