define('wnyc-studios-web-client/routes/flat-page', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),
    metadata: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads('fastboot.isFastBoot'),
    titleToken: function titleToken(model) {
      return model ? Ember.get(model, 'title') : '';
    },

    pageType: 'General Flatpage',
    model: function model(_ref) {
      var _this = this;

      var flatpage_path = _ref.flatpage_path;

      var flatPagePath = '/' + flatpage_path;
      return this.store.query('flat-page', {
        filter: {
          url: flatPagePath
        }
      }).then(function (flatPages) {
        return flatPages.get("firstObject");
      }).catch(function (err) {
        if (!err.isAdapterError) {
          throw err;
        } else {
          if (Ember.get(_this, 'isFastBoot')) {
            _this.set('fastboot.response.statusCode', 404);
          }
          _this.transitionTo('missing', '' + flatpage_path);
        }
      });
    },
    afterModel: function afterModel(model, transition) {
      this.get('metadata').setHeadData({
        path: Ember.get(transition, 'intent.url')
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('flatPage', model);
    },

    actions: {
      didTransition: function didTransition() {
        if (window && window.document) {
          this.get('dataLayer').push('Page Type', this.get('pageType'));
        }
        return true;
      },
      willTransition: function willTransition() {
        this.get('dataLayer').clear('Page Type');
        return true;
      }
    }
  });
});