define('wnyc-studios-web-client/components/studios-footer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'footer',
    classNames: ['studios-footer'],
    store: Ember.inject.service(),
    socialTitle: 'Follow WNYC Studios',
    socialLinks: Ember.A([{
      title: 'facebook',
      href: 'https://www.facebook.com/WNYCStudios/'
    }, {
      title: 'twitter',
      href: 'https://twitter.com/WNYCStudios'
    }, {
      title: 'instagram',
      href: 'https://www.instagram.com/wnycstudios/'
    }]),
    links: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var links = Ember.get(this, 'links');
      if (links === null) {
        Ember.get(this, 'store').findRecord('link-roll', 'wnyc-studios-footer-links').then(function (m) {
          return _this.set('links', m.get('linkroll'));
        }).catch(function (err) {
          if (!err.isAdapterError) {
            throw err;
          }
        });
      }
    }
  });
});