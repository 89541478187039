define('wnyc-studios-web-client/components/studios-story-fullbleed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'article',
    classNames: ['story', 'story--full-bleed']
  });
});