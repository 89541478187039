define('wnyc-studios-web-client/routes/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var setupFeaturedItem = function setupFeaturedItem(bucket) {
    var featuredItem = Ember.get(bucket, 'bucketItems.0.attributes');
    featuredItem.id = Ember.get(bucket, 'bucketItems.0.id');
    featuredItem.type = Ember.get(bucket, 'bucketItems.0.type');
    return featuredItem;
  };

  exports.default = Ember.Route.extend({
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),
    metadata: Ember.inject.service(),
    store: Ember.inject.service(),
    pageType: 'Homepage',
    model: function model() {
      return Ember.RSVP.hash({
        homepageLeadChunk: this.store.findRecord("chunk", "wnyc-studios-home-hero"),
        homepageMembershipChunk: this.store.findRecord("chunk", "wnyc-studios-home-membership"),
        featuredShows: this.store.findRecord("bucket", 'wnyc-studios-featured-shows'),
        trendingStories: this.store.findRecord("bucket", 'wnyc-studios-home-trending-stories'),
        promoBucketTop: this.store.findRecord("bucket", 'wnyc-studios-home-featured-promo-top'),
        promoBucketMiddle: this.store.findRecord("bucket", 'wnyc-studios-home-featured-promo-middle')
      });
    },
    afterModel: function afterModel() {
      this.get('metadata').setHeadData({
        path: ''
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      if (Ember.get(model, 'promoBucketTop.bucketItems.length')) {
        var featuredItem = setupFeaturedItem(Ember.get(model, 'promoBucketTop'));
        controller.set('promoBucketTop', Ember.get(model, 'promoBucketTop'));
        controller.set('promoItemTop', featuredItem);
      }
      if (Ember.get(model, 'promoBucketMiddle.bucketItems.length')) {
        var _featuredItem = setupFeaturedItem(Ember.get(model, 'promoBucketMiddle'));
        controller.set('promoBucketMiddle', Ember.get(model, 'promoBucketMiddle'));
        controller.set('promoItemMiddle', _featuredItem);
      }
    },

    actions: {
      didTransition: function didTransition() {
        if (window && window.document) {
          this.get('dataLayer').push('Page Type', this.get('pageType'));
        }
        return true;
      },
      willTransition: function willTransition() {
        this.get('dataLayer').clear('Page Type');
        return true;
      }
    }
  });
});