define('wnyc-studios-web-client/components/support-subscribe-buttons', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["support-subscribe-buttons"],
    usesSupportingCast: Ember.computed('show.slug', function () {
      var transitionedPodcasts = ['radiolab'];
      if (transitionedPodcasts.includes(this.get('show.slug'))) {
        return true;
      }
    }),
    listenText: Ember.computed('usesSupportingCast', function () {
      if (this.get('usesSupportingCast')) {
        return "Listen Now";
      }
      return "Listen For Free";
    }),
    supportText: Ember.computed('usesSupportingCast', function () {
      if (this.get('usesSupportingCast')) {
        return "Become A Member";
      }
      return "Support Us";
    }),
    actions: {
      toggleOverlay: function toggleOverlay() {
        if (this.get("showSubscribeOverlay")) {
          //closing the modal, focus back to header btn
          this.$('button.show-header__button').focus();
        }
        this.toggleProperty("showSubscribeOverlay");
      }
    }

  });
});