define('wnyc-studios-web-client/components/newsletter-unit', ['exports', 'wnyc-studios-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
    Returns a user facing string for a response.
  
    @method messageForResponse
    @private
    @param {Object} chimp ember-chimp instance
    @param {Object} response The response from the Mailchimp API.
    @return {String} A string to display.
  */
  function messageForResponse(chimp, response) {
    var responses = Ember.get(chimp, 'responses');
    if (response.result === 'success') {
      return responses.success;
    }
    if (!isNaN(parseInt(response.msg.charAt(0)))) {
      return responses.invalidError;
    }
    if (response.msg.endsWith("(#6592)")) {
      return responses.attemptsError;
    }
    return response.msg;
  }

  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.emberChimpResponses = {
        success: 'You\'re all set. Thank you for signing up!',
        error: 'Oops, something went wrong.  Please try again.',
        invalidError: 'Please enter a valid email.',
        attemptsError: "Too many subscribe attempts for this email address. Please try again in about 5 minutes."
      };
    },

    classNames: ['newsletter-unit'],
    formActionUrl: Ember.get(_environment.default, 'mailChimp.formActionUrl'),
    userId: Ember.get(_environment.default, 'mailChimp.userId'),
    listId: null,
    formAction: Ember.computed('formActionUrl', 'userId', 'listId', function () {
      return Ember.get(this, 'formActionUrl') + '?u=' + Ember.get(this, 'userId') + '&id=' + Ember.get(this, 'listId');
    }),
    handleResponse: function handleResponse(response) {
      if (this.isDestroyed) {
        return;
      }
      if (response.result === 'success') {
        this.set('value', '');
      }
      this.setProperties({
        chimpState: response.result,
        chimpSays: messageForResponse(this, response)
      });
    },

    optInChecked: true,
    show: null,
    optInTextList: Ember.computed('show', function () {
      if (!this._optInTextList) {
        var show = this.get('show');
        this._optInTextList = ['from New York Public Radio in accordance with our <a href="https://www.wnyc.org/terms/" target="_blank">Terms of Use</a>'];
        if (show) {
          var slug = Ember.get(show, 'slug');
          var producingOrganizations = Ember.get(show, 'producingOrganizations').filter(function (item) {
            // hard-coded per https://jira.wnyc.org/browse/GT-120
            return slug === 'trumpinc' && item.name === 'ProPublica';
          });
          producingOrganizations.forEach(function (item) {
            // hard-coded per https://jira.wnyc.org/browse/GT-120
            item.privacyUrl = 'https://www.propublica.org/legal/';
          });
          var self = this;
          producingOrganizations.forEach(function (item) {
            self._optInTextList.push('from ' + item.name + ' in accordance with its <a href="' + item.privacyUrl + '">Privacy Policy</a>');
          });
        }
      }
      return this._optInTextList;
    })
  });
});