define('wnyc-studios-web-client/models/appearance', ['exports', 'nypr-publisher-lib/models/appearance'], function (exports, _appearance) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _appearance.default;
    }
  });
});