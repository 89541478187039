define('wnyc-studios-web-client/components/sliding-bar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads('fastboot.isFastBoot'),
    classNames: ['sliding-bar'],
    didUpdateAttrs: function didUpdateAttrs() {
      if (!this.get('isFastBoot')) {
        this._updateStyle();
      }
    },
    didInsertElement: function didInsertElement() {
      this._updateStyle();
      var element = this.get('element');
      element.style.setProperty('transition', 'transform .25s ease-out');
    },
    _updateStyle: function _updateStyle() {
      var element = this.get('element');
      if (element) {
        var x = this.get('positionX') || 0;
        var width = this.get('width') || 160;
        element.style.setProperty('transform', 'translateX(' + x + 'px) scaleX(' + width + ')');
      }
    }
  });
});