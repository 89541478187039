define('wnyc-studios-web-client/models/bucket-item', ['exports', 'nypr-publisher-lib/models/bucket-item'], function (exports, _bucketItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _bucketItem.default;
    }
  });
});