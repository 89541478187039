define("wnyc-studios-web-client/components/story-metadata", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["story-metadata"],
    tagName: "footer"
  });
});