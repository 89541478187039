define('wnyc-studios-web-client/utils/touch-utils', ['exports', 'nypr-player/utils/touch-utils'], function (exports, _touchUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _touchUtils.default;
    }
  });
});