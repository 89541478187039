define('wnyc-studios-web-client/routes/series-detail/index', ['exports', 'wnyc-studios-web-client/mixins/listing-route'], function (exports, _listingRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_listingRoute.default, {
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),
    titleToken: function titleToken() {
      var linkroll = Ember.get(this.modelFor(this.channelType).channel, 'linkroll');
      if (linkroll && linkroll[0] && linkroll[0].title) {
        var link = linkroll[0];
        return ': ' + link.title;
      }
    },

    pageType: 'Series',

    model: function model(params, transition) {
      var _this = this;

      var channelPathName = 'series';
      var slug = transition.params["series-detail"].slug;

      var navSlug = 'recent_stories';

      var id = channelPathName + '/' + slug + '/' + navSlug + '/' + 1;
      Ember.set(this, 'pageNumbers.totalPages', 0);

      var controller = this.controllerFor('series-detail');
      controller.send('setNavSlug', navSlug);

      var pageSize = 9;
      if (navSlug === 'the-team') {
        pageSize = 50;
      }

      return this.store.findRecord('api-response', id, { adapterOptions: { limit: pageSize } }).then(function (m) {
        // wait until models are loaded to keep UI consistent
        Ember.set(_this, 'pageNumbers.page', 1);
        Ember.set(_this, 'pageNumbers.totalPages', Number(Ember.get(m, 'totalPages')));

        return m;
      });
    },
    afterModel: function afterModel() {
      var controller = this.controllerFor('series-detail');
      controller.send('updateNav');
    }
  });
});