define('wnyc-studios-web-client/components/tease-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['tease-list'],
    attributeBindings: ['data-test-selector'],
    'data-test-selector': 'episode-list'
  });
});