define('wnyc-studios-web-client/helpers/pluralize-item-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.pluralizeItemType = pluralizeItemType;
  function pluralizeItemType(itemType) {
    return itemType + 's';
  }

  exports.default = Ember.Helper.helper(pluralizeItemType);
});