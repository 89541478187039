define('wnyc-studios-web-client/components/featured-promo', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    item: null,
    title: null,
    classNames: ['featured-promo'],
    classNameBindings: ['typeClass'],
    itemType: Ember.computed.readOnly('item.type'),
    typeClass: Ember.computed('itemType', function () {
      var itemType = this.get('itemType');
      if (itemType) {
        return 'featured-promo--' + itemType;
      }
    }),
    image: Ember.computed.or('item.studiosMarqueeImage', 'item.imageMain'),
    imageW: Ember.computed('image.w', function () {
      return Math.min(1080, Ember.get(this, 'image.w'));
    }),
    imageH: Ember.computed('imageW', function () {
      return Math.floor(Ember.get(this, 'imageW') / 1.5);
    }),
    retinaImageW: Ember.computed.readOnly('image.w'),
    retinaImageH: Ember.computed('retinaImageW', function () {
      return Math.floor(Ember.get(this, 'retinaImageW') / 1.5);
    }),
    itemTitle: Ember.computed.readOnly('item.title'),
    itemTease: Ember.computed.readOnly('item.tease'),
    url: Ember.computed.readOnly('item.url'),
    show: Ember.computed('item', 'item.{type,headers.brand}', function () {
      var type = this.get('item.type');
      if (type === 'story') {
        return this.get('item.headers.brand');
      } else if (type === 'show' || type === 'series') {
        return this.get('item');
      }
    }),
    showUrl: Ember.computed('item.{type,slug}', 'show.slug', function () {
      var type = this.get('item.type');
      if (type === 'story') {
        return '/podcasts/' + this.get('show.slug');
      } else if (type === 'show') {
        return '/podcasts/' + this.get('item.slug');
      } else if (type === 'series') {
        return '/series/' + this.get('item.slug');
      }
    }),
    showLogo: Ember.computed.or('show.logoImage', 'show.image'),
    showTitle: Ember.computed.readOnly('show.title'),
    podcastLinks: Ember.computed.readOnly('item.podcastLinks'),
    audio: Ember.computed.readOnly('item.audio'),
    audioAvailable: Ember.computed.readOnly('item.audioAvailable'),
    itemId: Ember.computed.readOnly('item.slug')
  });
});