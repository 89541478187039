define('wnyc-studios-web-client/components/site-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['site-header'],
    classNameBindings: ['story:bright']
  });
});