define("wnyc-studios-web-client/routes/story", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),
    dataPipeline: Ember.inject.service(),
    googleAds: Ember.inject.service(),
    metadata: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads('fastboot.isFastBoot'),

    titleToken: function titleToken(model) {
      if (model.story.get('showTitle')) {
        return [model.story.get('title'), model.story.get('showTitle')];
      }
      return model.story.get('title');
    },
    model: function model(_ref, _ref2) {
      var _this = this;

      var podcast_slug = _ref.podcast_slug,
          item_type = _ref.item_type,
          slug = _ref.slug,
          extra = _ref.extra;
      var queryParams = _ref2.queryParams;

      if (extra === 'transcript') {
        window.location.replace("/transcript/" + slug);
      } else if (item_type !== 'episodes' && item_type != 'segments' && item_type != 'articles' && item_type !== undefined) {
        this.transitionTo("show-detail.page", "" + podcast_slug, item_type + "/" + slug);
      } else {
        var self = this;
        return this.store.findRecord("story", slug, { adapterOptions: { queryParams: queryParams } }).then(function (story) {
          var showSlug = podcast_slug !== undefined ? podcast_slug : Ember.get(story, "show");
          return Ember.RSVP.hash({
            urlTransitionNeeded: podcast_slug === undefined || item_type === undefined,
            story: Ember.RSVP.resolve(story),
            show: _this.store.findRecord('show', showSlug),
            channel: _this.store.findRecord("channel", "shows/" + showSlug, { adapterOptions: { limit: 9 } }),
            showsYouMightLike: _this.store.findRecord("bucket", showSlug + "-shows-you-might-like")
          });
        }).catch(function () {
          if (!isNaN(slug)) {
            self.transitionTo("show-detail.page", "" + podcast_slug, item_type + "/" + slug);
          } else if (podcast_slug && item_type) {
            if (Ember.get(self, 'isFastBoot')) {
              self.set('fastboot.response.statusCode', 404);
            }
            self.transitionTo('missing', "podcasts/" + podcast_slug + "/" + item_type + "/" + slug);
          } else {
            if (Ember.get(self, 'isFastBoot')) {
              self.set('fastboot.response.statusCode', 404);
            }
            self.transitionTo('missing', "story/" + slug);
          }
        });
      }
    },
    redirect: function redirect(_ref3) {
      var urlTransitionNeeded = _ref3.urlTransitionNeeded,
          story = _ref3.story,
          show = _ref3.show;

      if (urlTransitionNeeded) {
        this.transitionTo('story', Ember.get(show, 'slug'), Ember.get(story, 'pluralItemType'), Ember.get(story, 'slug'));
      }
    },
    afterModel: function afterModel(_ref4) {
      var story = _ref4.story,
          show = _ref4.show;

      this.get('metadata').setHeadData({
        type: 'article',
        path: "/podcasts/" + Ember.get(show, 'slug') + "/" + Ember.get(story, 'pluralItemType') + "/" + Ember.get(story, 'slug'),
        twitterCard: 'summary_large_image',
        description: Ember.get(story, 'tease'),
        image: Ember.get(story, 'imageMain'),
        feeds: [{
          type: 'application/rss+xml',
          title: Ember.get(show, 'title') + " Feed",
          href: Ember.get(show, 'rssFeed')
        }],
        itunesPodcastId: Ember.get(show, 'itunesPodcastId'),
        showSlug: Ember.get(story, "show")
      });
    },


    actions: {
      didTransition: function didTransition() {
        var _this2 = this;

        var _currentModel = this.currentModel,
            story = _currentModel.story,
            show = _currentModel.show;

        if (window && window.document) {
          Ember.get(this, 'googleAds').doTargeting({ show: show.get('slug') });
          Ember.get(this, 'dataLayer').setForType('story', story);
        }
        Ember.run.schedule('afterRender', function () {
          Ember.get(_this2, 'dataPipeline').reportItemView({
            cms_id: Ember.get(story, 'cmsPK'),
            item_type: Ember.get(story, 'itemType')
          });
        });
        return true;
      },
      willTransition: function willTransition() {
        Ember.get(this, 'googleAds').clearTarget('show');
        Ember.get(this, 'dataLayer').clearForType('story');
        return true;
      }
    }
  });
});