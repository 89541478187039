define('wnyc-studios-web-client/mirage-models/comment', ['exports', 'nypr-publisher-lib/mirage-models/comment'], function (exports, _comment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _comment.default;
    }
  });
});