define('wnyc-studios-web-client/components/fontawesome-node', ['exports', '@fortawesome/ember-fontawesome/components/fontawesome-node'], function (exports, _fontawesomeNode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fontawesomeNode.default;
    }
  });
});