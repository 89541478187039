define('wnyc-studios-web-client/routes/missing', ['exports', 'wnyc-studios-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    pageType: '404',
    titleToken: 'Not Found',

    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),
    store: Ember.inject.service(),
    metadata: Ember.inject.service(),

    model: function model() {
      var _this = this;

      Ember.run.schedule('afterRender', function () {
        return _this.get('dataLayer').send404();
      });

      return Ember.RSVP.hash({
        shows: this.store.query('show', { "site_id": _environment.default.siteId })
      });
    },
    afterModel: function afterModel(model, transition) {
      this.get('metadata').setHeadData({
        path: Ember.get(transition, 'intent.url')
      });
    },

    actions: {
      didTransition: function didTransition() {
        if (window && window.document) {
          this.get('dataLayer').push('Page Type', this.get('pageType'));
        }
        return true;
      },
      willTransition: function willTransition() {
        this.get('dataLayer').clear('Page Type');
        return true;
      }
    }
  });
});