define('wnyc-studios-web-client/components/studios-storyheader-alt', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'header',
    classNames: ['full-bleed__header', 'full-bleed__header--simple']
  });
});