define('wnyc-studios-web-client/services/poll', ['exports', 'ember-poll/services/poll'], function (exports, _poll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _poll.default;
    }
  });
});