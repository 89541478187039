define('wnyc-studios-web-client/helpers/producing-orgs', ['exports', 'nypr-publisher-lib/helpers/producing-orgs'], function (exports, _producingOrgs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _producingOrgs.default;
    }
  });
  Object.defineProperty(exports, 'producingOrgs', {
    enumerable: true,
    get: function () {
      return _producingOrgs.producingOrgs;
    }
  });
});