define('wnyc-studios-web-client/controllers/application', ['exports', 'wnyc-studios-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    dj: Ember.inject.service(),
    hifi: Ember.inject.service(),
    bucket: Ember.computed.alias('model'),

    showPlayer: Ember.computed.reads('dj.showPlayer'),

    isHomepage: Ember.computed.match('currentRouteName', /^index(_loading)?$/),

    bottomAdIsVisible: false,
    bottomAdDismissed: false,
    sideNavIsOpen: false,
    mailchimpEndpoint: _environment.default.optInAPI + '/mailchimp',
    politicsBriefNewsletter: _environment.default.politicsBriefNewsletter,

    actions: {
      dismissBottomAd: function dismissBottomAd() {
        this.set('bottomAdIsVisible', false);
        this.set('bottomAdDismissed', true);
      },
      handleAdhesionSlotRendered: function handleAdhesionSlotRendered(event) {
        if (!event.isEmpty && !this.get('bottomAdDismissed')) {
          this.set('bottomAdIsVisible', true);
        }
      }
    }
  });
});