define('wnyc-studios-web-client/components/show-tease', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['show-tease'],
    imageSize: 100,
    imageSizeForRetina: Ember.computed('imageSize', function () {
      var imageSize = Ember.get(this, 'imageSize') || 0;
      return imageSize * 2;
    })
  });
});