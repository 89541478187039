define('wnyc-studios-web-client/components/studios-footer/footer-links', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['studios-footer__link-list']
  });
});