define('wnyc-studios-web-client/components/social-links', ['exports', 'nypr-publisher-lib/components/social-links'], function (exports, _socialLinks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _socialLinks.default;
    }
  });
});