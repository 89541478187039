define('wnyc-studios-web-client/components/side-nav-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['side-nav-button'],
    actions: {
      sendAction: function sendAction() {
        this.sendAction();
      }
    }
  });
});